
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const geoSlice = createApi({
    reducerPath: 'geo',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=1e481047-f573-4480-8022-5db688d3508d&suggest_apikey=7199318a-9f03-441b-abb5-e2b279226123.' }),
    tagTypes: ['Geocoder'],
    endpoints: builder => ({
        getCoordinats: builder.query({
            query: address => `${address}&results=1`,
            providesTags: ['Geocoder']
        }),
    })
});
export const { useGetCoordinatsQuery } = geoSlice;
